import React, { useState } from "react";
import axios from "axios";

const ContactOneForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://localhost:5001/api/contact', formData)
      .then(response => {
        window.alert('Capivara Coin: Mensagem enviada com sucesso');
      })
      .catch(error => {
        window.alert('Capivara Coin: Erro ao enviar mensagem');
      });
  };

  return (
    <div className="contact-form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-grp">
              <input 
                type="text" 
                name="name" 
                placeholder="Qual seu nome?" 
                required 
                onChange={handleChange} 
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-grp">
              <input 
                type="email" 
                name="email" 
                placeholder="Qual seu email?" 
                required 
                onChange={handleChange} 
              />
            </div>
          </div>
        </div>
        <div className="form-grp">
          <textarea 
            name="message" 
            placeholder="Escreva sua mensagem"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="submit-btn text-center">
          <button type="submit" className="btn">
            Enviar 
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactOneForm;
