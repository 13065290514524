import React, { useState } from "react";
import axios from "axios";

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://localhost:5001/api/newsletter', { email })
      .then(response => {
        alert('Capivara Coin: Inscrição realizada com sucesso');
      })
      .catch(error => {
        alert('Capivara Coin: Erro ao realizar inscrição');
      });
  };

  return (
    <section className="newsletter-area">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="newsletter-wrap">
              <div className="newsletter-content">
                <h2 className="title">Receba atualizações</h2>
              </div>
              <div className="newsletter-form">
                <form onSubmit={handleSubmit}>
                  <input 
                    type="text" 
                    placeholder="Enter your email" 
                    required 
                    onChange={handleChange} 
                  />
                  <button type="submit" className="btn btn-two">
                    Inscrever-se
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
