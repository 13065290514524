import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect, useState } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import CookieConsent, { checkCookieConsent } from './components/LGPD/banner';
import { useCookies } from 'react-cookie';

function App() {
  const [cookies] = useCookies(['userConsent']);
  const [cookiesAccepted, setCookiesAccepted] = useState(checkCookieConsent(cookies));

  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
  };

  return (
    <>
      <CookieConsent onAccept={handleAcceptCookies} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home-two" element={<HomeTwo />} />
       
        <Route path="login" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
