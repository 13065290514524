import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { scrollToTop } from "../../lib/helpers";

const FooterOne = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://localhost:5001/api/newsletter', { email })
      .then(response => {
        alert('Capivara Coin: Inscrição realizada com sucesso');
      })
      .catch(error => {
        alert('Capivara Coin: Erro ao realizar inscrição');
      });
  };

  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                      A primeira MEMECOIN voltada ao Brasil.
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Links uteis</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Contato</Link>
                      </li>
                      <li>
                        <Link to="/">WhiterPaper</Link>
                      </li>
                      <li>
                        <Link to="/">Criadores</Link>
                      </li>
                      <li>
                        <Link to="/">Airdrop</Link>
                      </li>
                      <li>
                        <Link to="/">Termos e Serviços</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Communidade</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/">Parceiros</Link>
                      </li>
                      <li>
                        <Link to="/">Sugestões</Link>
                      </li>
                      
                      <li>
                        <Link to="/">Newsletters</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Receba novidades</h4>
                  <div className="footer-newsletter">
                    <p>
                      Cadastre seu email para receber atualizações
                    </p>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        placeholder="Info@gmail.com"
                        required
                        onChange={handleChange}
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2024. Todos os direitos são reservados</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#">Termos e condições</Link>
                    </li>
                    <li>
                      <Link to="#">Politica de Privacidade</Link>
                    </li>
                    <li>
                      <Link to="#">Entrar / Sair</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
