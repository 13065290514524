import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "MAI de 2024",
      title: "Conceito",
      info: [
        "Reunião do Time",
        "Escolha do nome",
        "Inicio do Whiterpaper",
      ],
    },
    {
      roadmapTitle: "JUN de 2024",
      title: "Pesquisa",
      info: [
        "Programa perguntas e questionamentos",
        "MVP interno do contrato da Rede",
        "Criação do SDK e Servidor",
      ],
    },

    {
      roadmapTitle: "JUL de 2024",
      title: "Teste beta",
      info: [
        "Lançamento público de testnet",
        "Lançamento da página de divulgação",
        "Coleta de informações",
      ],
    },

    {
      roadmapTitle: "AGO de 2024",
      title: "Abertura para apoiadores",
      info: [
        "Wallet para apoiadores",
        "Divulgação do AIRDROP",
        "ADS para crescer a comunidade",
      ],
    },
    {
      roadmapTitle: "SET de 2024",
      title: "Listagem",
      info: [
        "Divulgação da blockchain e codigo do token",
        "Distribuição de tokens conforme WhiterPaper",
        "Listagem em DEFI",
      ],
    },
    {
      roadmapTitle: "OUT de 2024 ",
      title: "Apoio aos Desenvolvedores",
      info: [
        "Liberação de tokens dos DEV",
        "Listagem em Exchange com moedas FIAT",
        "Divulgação de Dados",
      ],
    },
    {
      roadmapTitle: "2025",
      title: "RECONHECIMENTO",
      info: [
        <>
         Comunidade fortalecida <br /> MEMECOINS de outros animais Brasileiros
        </>,
        "Criação da Própria Exchange",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Roadmap </span>
              <h2 className="title">
                A Capivara  <br /> COIN <br></br><span>é transparente</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
