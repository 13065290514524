import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

export const checkCookieConsent = (cookies) => {
  return cookies.userConsent === 'true';
};

const CookieConsent = ({ onAccept }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['userConsent']);
  const [showBanner, setShowBanner] = useState(!checkCookieConsent(cookies));

  const acceptCookies = () => {
    setCookie('userConsent', 'true', { path: '/', maxAge: 3600 * 24 * 365 });
    setShowBanner(false);
    onAccept();
  };

  const rejectCookies = () => {
    removeCookie('userConsent', { path: '/' });
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <>
          <div style={styles.overlay}></div>
          <div style={styles.banner}>
            <p style={styles.text}>
              Utilizamos cookies para melhorar sua experiência. Ao continuar navegando, você concorda com a nossa política de cookies...
            </p>
            <div style={styles.buttons}>
              <button style={{ ...styles.button, ...styles.accept }} onClick={acceptCookies}>Aceitar</button>
              <button style={{ ...styles.button, ...styles.reject }} onClick={rejectCookies}>Recusar</button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  banner: {
    position: 'fixed',
    bottom: '10px',
    width: '100%',
    backgroundColor: '#2d2d2d',
    color: 'white',
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
  },
  text: {
    margin: '0 0 10px 0',
    fontSize: '16px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
  },
  accept: {
    backgroundColor: '#4CAF50',
    color: 'white',
  },
  reject: {
    backgroundColor: '#f44336',
    color: 'white',
  },
};

export default CookieConsent;
