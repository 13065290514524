import React from "react";
import LayoutOne from "../../layouts/LayoutOne";

const Login = () => {
  return (
    <LayoutOne>
      <main>
        
      </main>
    </LayoutOne>
  );
};

export default Login;
