import React, { useEffect, useState } from 'react';
import axios from 'axios';

const BTCPrice = () => {
  const [btcPrice, setBtcPrice] = useState(null);

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
        setBtcPrice(response.data.bitcoin.usd);
      } catch (error) {
        console.error('Error fetching BTC price:', error);
      }
    };

    fetchBTCPrice();
    const interval = setInterval(fetchBTCPrice, 60000); // Atualiza o preço a cada 60 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);

  const cicPrice = btcPrice ? (btcPrice * 3.17738).toFixed(2) : '...';

  return (
    <span>
      1 BTC = ${btcPrice || '...'} = {cicPrice} CIC
    </span>
  );
};

export default BTCPrice;
